h2 {
  text-align: center;
  font-size:  22px;
  
}

.contact-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 18.5rem;
}

.form-container {
  width: 50%;
  @media (max-width: 767px) {
    width: 80%;
   }
}

input,
#message {
  width: 100%;
  background-color: #00000040;
  border-radius: 0.3rem;
 
  outline: none;
  color: white;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Russo One', sans-serif;
}

input {
  height: 1.5rem;
}

::placeholder {
  color: white;
  font-weight: 400;

}

#message {
  height: 6rem;
  resize: none;

}

button {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  width: 102%;
  padding: 0.5rem 0;
  background-color: transparent;
  font-family: 'Russo One', sans-serif;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Ensure the overlay is above other elements */
}

.success-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0,0,0,0.3); 
  width: 100%; 
  min-height: 12rem;
  border-radius: 0.3rem;
  border: 0.07rem solid rgba(255, 255, 255, 0.7);
  position: relative;
  font-weight: bold;
}

.success-message p {
  font-size: large;
  font-weight: bold;
  padding: 1.5rem;
}
.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.error-message {
  padding:0.3rem;
  color: white;
  background-color: rgb(255,59,59);
  border-radius: 0.2rem;
 
}

label {
  display: none;
}