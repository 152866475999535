.item {
  height: 3.7rem;
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  gap: 1rem; /* Space between icon and title */
}

.icon {
  width: 2.5rem; /* Smaller icon size */
  height: 2.5rem; /* Make the icon fit next to the text */
}

.title {
  font-size: 1rem;
  padding: 6%;
}

.item.active .title {
  font-size: 1rem;
}

.sub-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}

.sub-container li {
  list-style-type: circle;
  padding: 0.5rem 0;
  margin-left: 1.5rem;
  font-size: 1rem;
}

.active-subheading {
  cursor: default;
}

.item.active:nth-child(1) {
  background: linear-gradient(90deg, var(--transparent), var(--purple) 100%);
  border-radius: var(--radius-right);
}

.item.active:nth-child(2) {
  background: linear-gradient(90deg, var(--transparent), var(--blue) 100%);
  border-radius: var(--radius-right);
}

.item.active:nth-child(3) {
  background: linear-gradient(90deg, var(--transparent), var(--cyan) 100%);
  border-radius: var(--radius-right);
}

.sub-container-1.active-subheading h3,
.sub-container-2.active-subheading h3,
.sub-container-3.active-subheading h3 {
  border-radius: var(--radius);
}

.sub-container-1.active-subheading h3 {
  background: linear-gradient(90deg, var(--purple), var(--transparent) 100%);
}

.sub-container-2.active-subheading h3 {
  background: linear-gradient(90deg, var(--blue), var(--transparent) 100%);
}

.sub-container-3.active-subheading h3 {
  background: linear-gradient(90deg, var(--cyan), var(--transparent) 100%);
}

.item.active {
  position: relative;
  cursor: default;
}

.p-container {
  display: block;
  white-space: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-height: 550px;
  overflow-y: auto;
  margin: 10px 0;
  color: white;
  font-weight: bold;
  background-color: rgba(84, 3, 81, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.active-subheading .p-container {
  display: block;
  font-size: 1rem;
}

.icon-title-container {
  display: flex;
  gap: 1.2rem;
  margin-bottom: 0.5rem;
  cursor: default;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.sub-container-1 h3,
.sub-container-2 h3,
.sub-container-3 h3 {
  position: relative;
  margin: 0.2rem 0;
  padding: 0.5rem 0 0.5rem 1rem;
}

@media (max-width: 400px) {
  .item {
    height: 2rem;
    width: 71%;
  }

  .sub-container {
    right: 1rem;
  }

  .item.active .title {
    font-size: 1rem;
  }

  .title {
    font-size: 0.8rem;
  }
}

@media (max-width: 700px) {
  .menu {
    width: 15rem; /* Adjust menu width for smaller screens */
  }

  .sub-container {
    right: 5vw; /* Adjust positioning for smaller screens */
  }
}
