@import url('https://fonts.googleapis.com/css2?family=Russo+One^&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

body {
  font-family: 'Russo One', sans-serif;
  font-family: 'Space Grotesk', sans-serif;
  color: white;
  -webkit-tap-highlight-color: transparent;
  overflow: auto;
  height: auto;
}

:root {
  --purple: #540351;
  --blue: #3627b1;
  --cyan: #25afce;
  --transparent: #00000000;
  --radius-left: 3rem 0 0 3rem;
  --radius-right: 0 3rem 3rem 0;
}

button:hover,
.class-sub-container a:hover,
.project-item:hover,
.skill-item:hover,
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-2:not(.active-subheading):hover {
  background-image: linear-gradient(
    90deg,
    #ffffff00,
    #ffffff42,
    50%,
    #ffffff00
  );
}

footer {
  position: relative;
  width: 100%;
  color: white;
  padding: 10px;
  text-align: center;
}

main-content {
  min-height: calc(100vh- 100px);
  padding-bottom:  100px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    min-height: 120px;
   }
}

/* .body-container{
  display: flex;
  align-items:center;
  justify-content: space-evenly;
  width: '100%';
  height: 90vh;

  @media (max-width: 767px) {
   flex-direction: column; 
   padding-top: 5%;
  } */
  .body-container {
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
     max-width: 50%;
     margin: auto;
     text-align: center;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   }

