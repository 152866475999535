.skill-menu {
  width: 50%;
  flex-direction: row;
  justify-content: center; 
  align-items: flex-start;    
  position: relative;
  top: 20%;
  margin: 0 auto; 
  gap:2rem;
  
}

.skill-item {
  height: auto;
  width: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.skill-title {
  font-size: 1rem;
  padding: 0;
  text-align: center;
}

.skill-item.activeSkill .skill-title {
  font-size: 1.5rem;
}

.activeSkill {
  border-radius: var(--radius-left);
  cursor: default;
}

.skill-item.activeSkill:nth-child(1) {
  background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
}
.skill-item.activeSkill:nth-child(2) {
  background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
}

.skill-icon {
  width: 5rem;
  height: 5rem; 
  margin-bottom: 0.5rem;
}

.skill-sub-container-1 h3,
.skill-sub-container-2 h3 {
  position: relative;
  margin: 0;
}

.menu {
  width: 12rem;
  display: flex;
  flex-direction: row;
}

skill-sub-container-1,
skill-sub-container-2 {
  display: flex;
  justify-content: right;
  gap: 2rem;
}

.level-container {
  display: flex;
  gap: 0.3rem;
}

.level-point {
  width: 3rem;
  height: 2rem;
  border-radius: 0.2rem;
}

.filled {
  background: linear-gradient(180deg, #236473, #25afce, #7ce7ff);
}
.unfilled {
  background: linear-gradient(180deg, #1c8298, #074654, #074654);
}

.skill-sub-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1100px) {
  .skill-item.activeSkill .skill-title {
    font-size: 1rem;
  }
  .skill-icon {
    display: none;
  }
  .level-point {
    width: 1.2rem;
    height: 1rem;
    border-radius: 0.1rem;
  }
  .level-container {
    gap: 0.15rem;
  }

  .skill-sub-container-1,
  .skill-sub-container-2 {
    gap: 0.5rem;
  }
  .skill-sub-container {
    margin-top: 2rem;
    gap: 0.2rem;
  }
  h1 {
    font-size: 0.8rem;
  }

  h2,
  h3,
  p {
    font-size: 0.7rem;
  }

  .skill-menu {
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
  
  }

  .skill-item {
    height: 2rem;
    width: 12rem;
  }
}

@media (max-width: 700px) {
  .avatar.about {
    z-index: -1;
    /* height: 85vh;
    bottom: 0vh; */
    width: 62%;
    opacity: 50%;
    /* left: 61%; */
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* position: absolute;
    top: 21%;
    left: 30%; */
    transform: translateY(-50%, 0);
    gap: 20px;
  }

  .menu-item {
   margin: 0 10px;
  }
  .skils-menu {
    top: 30%;
    left: 50%;
  
  }
  
}
