.blog-container {
    padding: 20px;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    box-sizing: border-box;
  }
  
  .blog-container h1 {
    text-align: center;
  }
  
  .posts-list {
    display: grid; /* Use grid for layout */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive 3-column grid */
    gap: 20px; /* Space between posts */
    justify-content: center; /* Center the grid if not full width */
  }
  
  .post {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    background:linear-gradient(10deg, var(--transparent), var(--purple) 50%);
};
  

  .post-content .medium-feed-image {
    width: 100%;
    height: 125px;
    overflow: hidden;
  }
  
  .post img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    object-fit: cover;
    max-height: 160px;;
  }
  
  .post h2 {
    margin: 0;
    color: whitesmoke;
    font-size:15px;
    text-wrap: wrap;
    /* background: grey; */
  }
  
  .post p {
    margin: 5px 0;
    line-height: 1.6;
    color: seashell;
    /* background: grey; */
  }
  
  .post a {
    text-decoration: none;
    color: inherit;
    width: 100%;
    color:seashell;
    /* background: grey; */
  }
  
  /* Media queries for responsiveness */
@media (max-width: 1024px) {
    .posts-list {
      grid-template-columns: repeat(2, 1fr); /* Two posts per row */
    }
  }
  
  @media (max-width: 768px) {
    .posts-list {
      grid-template-columns: 1fr; /* One post per row */
    }
  }