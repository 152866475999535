#playerStats {
  width: 100%;
  height: 4rem;
  /* position: fixed; */
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  align-items: center;
  top: 3rem;
  left: 3rem;
  margin: 10px;
}

h1 {
  margin: 0;
  font-size: 1.5rem;
}



#playerStats_separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

}

.separator {
  font-size: 1.5rem;
  color: white;
  padding: 0 10px;
}


@media (max-width:1100px) {
  #playerStats {
    /* left: 50%; */
    /* transform: translateX(-50%); */
    width: 8rem;
    height: .5rem;
    top: 2rem;
    
  }
  h1 {
    font-size: 0.8rem;
  }

  
  h3,
  p {
    font-size: 18px;
  }

}

@media (max-width: 700px){
  #playerStats {
    flex-direction: row;
    align-items: center;
  }
}
